var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PageHeader',{attrs:{"items":_vm.items}}),_c('validation-observer',{ref:"simpleRules"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"label":_vm.$t('Name'),"tooltipMessage":_vm.$t('Name'),"required":true,"disabled":true},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"name":"Credits","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"type":"number","disabled":true,"label":_vm.$t('Credits'),"required":true},model:{value:(_vm.form.credits),callback:function ($$v) {_vm.$set(_vm.form, "credits", $$v)},expression:"form.credits"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"name":"Costs","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"disabled":true,"type":"number","label":_vm.$t('Costs'),"required":true},model:{value:(_vm.form.costs),callback:function ($$v) {_vm.$set(_vm.form, "costs", $$v)},expression:"form.costs"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.image.url)?_c('div',{staticClass:"col-md-6 mb-2 d-flex flex-column"},[_c('label',{staticClass:"input-label form-label"},[_vm._v(_vm._s(_vm.$t("Image"))+" ")]),_c('div',{staticClass:"news-image"},[_c('img',{attrs:{"src":_vm.image.url}})])]):_vm._e()])])])]),_c('div',{staticClass:"card mt-3"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('h2',[_vm._v(_vm._s(_vm.$t("Country Packages")))])]),_c('div',{staticClass:"d-flex justify-content-between align-items-center mb-1 mt-1"},[_c('div',{staticClass:"custom-search d-flex justify-content-between"},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("Rows per page"))+" ")]),_c('b-form-select',{staticClass:"ml-1",attrs:{"options":['25', '50', '100']},on:{"input":function (value) { return _vm.onPerPageChange({ pageLength: value }); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}})],1)])],1),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"form-control",attrs:{"autocomplete":"off","type":"text","name":"search","placeholder":_vm.$t('Search…')},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}})])]),_c('div',{staticClass:"table-responsive api-keys-table"},[_c('vue-good-table',{attrs:{"styleClass":"vgt-table striped","columns":_vm.columns,"fixed-header":false,"rows":_vm.rows,"search-options":{
            enabled: false,
            externalQuery: _vm.searchTerm,
            trigger: 'enter',
          },"pagination-options":{
            enabled: true,
            perPage: _vm.pageLength,
          },"mode":"remote"},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'costs')?_c('span',[_vm._v(" "+_vm._s(_vm.$formatter(props.row.costs, _vm.$i18n.locale, "EUR", false, 2, 2)))]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-end flex-wrap"},[_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":_vm.totalRecords,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) { return _vm.onPageChange({ currentPage: value }); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }